import { render, staticRenderFns } from "./TaskStatusName.vue?vue&type=template&id=762bde3c&scoped=true&"
import script from "./TaskStatusName.vue?vue&type=script&lang=js&"
export * from "./TaskStatusName.vue?vue&type=script&lang=js&"
import style0 from "./TaskStatusName.vue?vue&type=style&index=0&id=762bde3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "762bde3c",
  null
  
)

export default component.exports